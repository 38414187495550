<template>
  <r-e-dialog title="处理" class="layer-contract-detail" :visible.sync="dialogVisible" :show-footer="type!=='chakan'"
              top="10vh"
              width="670px" :showFooter="false"
              fullscreen @close="clickCancel">
    <div style="height: 75vh;">
      <div style="height: 100%;width: 100%;">
        <el-steps :active="active" style="margin-bottom: 20px;" :finish-status="titleTyle" process-status="finish">
          <el-step :title="typesuijisu === 2 ? '申请-楼栋管理员发起' : '发起'">
            <!--            <div slot="description" v-if="formPublish.workOrderStatus === '已撤销'">-->
            <!--              <div>申请人:{{formPublish.startUserName}} </div>-->
            <!--              <div>申请时间: {{timeFormat(formPublish.startTime,"yyyy-MM-dd HH:mm:ss")}} </div>-->
            <!--            </div>-->
            <div slot="description" v-if="formPublish.workOrderStatus !== '已撤销'">
              <div v-if="formPublish.startUserName">申请人:{{ formPublish.startUserName }}</div>
              <div v-if="!formPublish.startUserName">申请人: {{ userName }}</div>
              <div>申请时间: {{ timeFormat(formPublish.startTime, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
            <div slot="description" v-else>
              <div>申请人:{{ formPublish.revokeName }}</div>
              <div>申请时间: {{ timeFormat(formPublish.revokeDate, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
          </el-step>
          <el-step title="资产部审核">
            <div slot="description" v-if="active > 0 && formPublish.workOrderStatus !== '已撤销' ">
              <div v-if="formPublish.assetDeptAuditName">审核人:{{ formPublish.assetDeptAuditName }}</div>
              <div v-if="!formPublish.assetDeptAuditName">审核人:{{ userName }}</div>
              <div>时间:{{ timeFormat(formPublish.assetDeptAuditDate, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
          </el-step>
          <el-step v-if="typesuijisu === 2" title="财务部审核">
            <div slot="description" v-if="active > 1 && formPublish.workOrderStatus !== '已撤销' ">
              <div v-if="formPublish.financeDeptAuditName">审核人:{{ formPublish.financeDeptAuditName }}</div>
              <div v-if="!formPublish.financeDeptAuditName">审核人:{{ userName }}</div>
              <div>时间:{{ timeFormat(formPublish.financeDeptAuditDate, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
          </el-step>
          <el-step  title="管理层审核" v-if="topTlile && typesuijisu === 3">
            <div slot="description" v-if="active > 2 && formPublish.workOrderStatus !== '已撤销' ">
              <div v-if="formPublish.adminAuditName">审核人:{{ formPublish.adminAuditName }}</div>
              <div v-if="!formPublish.adminAuditName">审核人:{{ userName }}</div>
              <div>时间:{{ timeFormat(formPublish.adminAuditDate, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
          </el-step>
          <el-step  title="管理层审核" v-if="typesuijisu !== 3">
            <div slot="description" v-if="active > 2 && formPublish.workOrderStatus !== '已撤销' ">
              <div v-if="formPublish.adminAuditName">审核人:{{ formPublish.adminAuditName }}</div>
              <div v-if="!formPublish.adminAuditName">审核人:{{ userName }}</div>
              <div>时间:{{ timeFormat(formPublish.adminAuditDate, "yyyy-MM-dd HH:mm:ss") }}</div>
            </div>
          </el-step>
          <!--          <el-step v-if="typesuijisu !== 3" title="管理员审核">-->
          <!--            <div slot="description" v-if="active>=2">-->
          <!--              <div>审核人:{{formPublish.adminAuditName}} </div>-->
          <!--              <div>时间:{{timeFormat(formPublish.adminAuditDate,"yyyy-MM-dd HH:mm:ss")}} </div>-->
          <!--            </div>-->
          <!--          </el-step>-->
          <!--          <el-step v-if="typesuijisu === 3" title="资产部审核">-->
          <!--            <div slot="description" v-if="active>=2">-->
          <!--              <div>审核人: {{formPublish.assetDepartAuditName}}</div>-->
          <!--              <div>时间: {{timeFormat(formPublish.assetDepartAuditDate,"yyyy-MM-dd HH:mm:ss")}} </div>-->
          <!--            </div>-->
          <!--          </el-step>-->
          <!--          <el-step v-if="days > 14" title="管理层审核">-->
          <!--            <div slot="description" v-if="active>=2">-->
          <!--              <div>审核人:{{formPublish.adminAuditName}} </div>-->
          <!--              <div>时间:{{timeFormat(formPublish.adminAuditDate,"yyyy-MM-dd HH:mm:ss")}} </div>-->
          <!--            </div>-->
          <!--          </el-step>-->
          <!--          v-if="typesuijisu === 3"-->
          <el-step title="查看"></el-step>
        </el-steps>

        <div style="height: 90%;display: flex;flex-flow: column wrap;">
          <!--合同审批-->
          <template v-if="typesuijisu === 1">
            <div>
              <div class="box_tap">
                <h3>承租人信息</h3>
              </div>
              <div class="box_bnt" v-if="active !== 0"  style="height: 120px;padding-top: 15px">
                <div class="item">
                  承租人：{{ formPublish.leasorName }}
                </div>
                <div class="item">
                  手机号码：{{ formPublish.leasorPhone }}
                </div>
                <div class="item">
                  证件号码：{{ formPublish.idCard }}
                </div>
                <div class="item">
                  承租人类型：{{ formPublish.leasorType === '个人' ? '个人' : '企业' }}
                </div>
                <div class="item">
                  紧急联系人：{{ formPublish.emergencyPerson }}
                </div>
                <div class="item">
                  紧急联系人电话：{{ formPublish.emergencyPhone }}
                </div>
                <div class="item">
                  身份证地址：{{ formPublish.idcardAddress }}
                </div>
                <div class="item">
                  邮箱：{{ formPublish.email }}
                </div>
              </div>
              <div class="box_bnt" v-else style="height: 120px;padding-top: 15px">
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 承租人：</div>
                  <el-input class="input_sty" v-model="formPublish.leasorName"></el-input>
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 手机号码：</div>
                  <el-input class="input_sty"  v-model="formPublish.leasorPhone"></el-input>
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 证件号码：</div>
                  <el-input class="input_sty" v-model="formPublish.idCard"></el-input>
                </div>
                <div class="item">
                  承租人类型：{{ formPublish.leasorType === '个人' ? '个人' : '企业' }}
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 紧急联系人：</div>
                  <el-input class="input_sty" v-model="formPublish.emergencyPerson"></el-input>
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 120px"> 紧急联系人电话：</div>
                  <el-input class="input_sty" v-model="formPublish.emergencyPhone"></el-input>
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 身份证地址：</div>
                  <el-input class="input_sty" v-model="formPublish.idcardAddress"></el-input>
                </div>
                <div class="item" style="display: flex">
                  <div style="width: 90px"> 邮箱：</div>
                  <el-input class="input_sty" v-model="formPublish.email"></el-input>
                </div>
              </div>
            </div>
            <div>
              <div class="box_tap">
                <h3>房源信息</h3>
              </div>
              <div class="box_bnt" style="height: 50px;padding-top: 15px">
                <div class="item">
                  房间：{{ formPublish.apartmentName }}
                </div>
                <div class="item">
                  房型：{{ formPublish.hourseLayout }}
                </div>
                <div class="item">
                  建筑面积(㎡)：{{ formPublish.outsideArea }}
                </div>
                <div class="item">
                  地址：{{ formPublish.apartmentAddress }}
                </div>


              </div>
            </div>
            <div>
              <div class="box_tap">
                <h3>合同信息</h3>
              </div>
              <div class="box_bnt" style="height: 140px">
                <div class="item">
                  合同周期：{{ formPublish.contractPeriod }}
                </div>

                <div class="item" v-if="formPublish.myNmae === '单价'">
                  单价(元/㎡)：{{ formPublish.monthPriceAmount / 100 }}
                </div>
                <div class="item">
                  月租(元/月)：{{ formPublish.monthlyRent / 100 }}
                </div>
                <div class="item">
                  押金（元）：{{ formPublish.depositAmount / 100 }}
                </div>
                <div class="item">
                  付款方式：{{ formPublish.paymentMethodsName }}
                </div>
                <div class="item">
                  签约类型：{{ formPublish.isExchange }}
                </div>
                <div class="item">
                  经办人：{{ formPublish.manager }}
                </div>
                <div class="item">
                  管理人：{{ formPublish.keeper }}
                </div>

                <div class="item"  >
                  附&emsp;&emsp;件： <a  @click="funUrlLookPdf" style="text-decoration: none;" href="javascript:;" >查看附件</a><a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" v-if="active===0">添加附件</a>
                </div>

              </div>
            </div>
            <div>
              <div class="box_tap">
                <h3>备注</h3>
              </div>
              <div class="item" style="display: flex;align-items: center;">
                <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"
                          style="width: 350px;margin-top: 20px" v-model="HETrentUpdateReason"
                          placeholder="请输入内容"></el-input>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 400px;margin-top: 25px;">
                  {{ formPublish.contractWorkOrderRemark }}
                </div>

              </div>
              <div style="margin-top: 10px">
                <el-badge v-if="formPublish.handleNum > 0" :value="formPublish.handleNum" :max="99" class="item">
                  <el-button @click="chuLijilu('合同')" size="small" type="primary">处理记录</el-button>
                </el-badge>
                <el-button v-else @click="chuLijilu('合同')" size="small" type="primary">处理记录</el-button>
              </div>
              <!--        <div class="box_bnt" style="height: 90px">-->
              <!--          <div class="item" style="display: flex;align-items: center;">-->
              <!--            <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"  style="width: 350px;margin-top: 20px" v-model="formPublish.rentUpdateReason" placeholder="请输入内容"></el-input>-->
              <!--            <div v-if="formPublish.workOrderStatus !== '待发起'" style="border-bottom: solid 1px #BBBBBB;width: 400px;">{{formPublish.rentUpdateReason}}</div>-->
              <!--          </div>-->
              <!--        </div>-->
            </div>

          </template>

          <!--租金审批-->
          <div v-if="typesuijisu === 2">
            <div class="box_tap">
              <h3>房源信息</h3>
            </div>
            <div class="box_bnt_zujin">
              <div class="item">
                房间：{{ formPublish.apartmentName }}
              </div>
              <div class="item">
                房型：{{ formPublish.hourseLayout }}
              </div>
              <div class="item">
                建筑面积(㎡)：{{ formPublish.outsideArea }}
              </div>

              <div class="item" style="display: flex">
                <div v-if="formPublish.myNmae === '单价'" style="width: 302px">
                  单价(元/㎡)：{{ (formPublish.monthlyRent / 100).toFixed(2) }}
                </div>
                <div v-if="formPublish.myNmae === '单价'">租金(元/月)：{{ formPublish.MYmonthlyRent }}</div>
                <div v-if="formPublish.myNmae !== '单价'">租金(元/月)：{{ formPublish.monthlyRent / 100 }}</div>

              </div>
              <!--              <div class="item">-->
              <!--                地址：{{formPublish.apartmentAddress}}-->
              <!--&lt;!&ndash;                押金：{{formPublish.depositAmount/100}}元&ndash;&gt;-->
              <!--              </div>-->
              <div class="item" style="display: flex;align-items: center">
                地址：{{ formPublish.apartmentAddress }}
              </div>
              <div class="item" style="display: flex;align-items: center">
                <div  v-if="formPublish.myNmae !== '单价'">申请修改租金（元/月）：</div>
                <div v-if="formPublish.myNmae === '单价'">申请修改单价(元/m²)：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" style="width: 100px"
                          v-model="formPublish.rentUpdatePrice" placeholder="请输入内容"></el-input>
                <span v-if="formPublish.workOrderStatus === '待发起'"></span>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 200px;text-align: center">
                  {{ formPublish.rentUpdatePrice }}
                </div>
                <span v-if="formPublish.workOrderStatus !== '待发起'"></span>
              </div>

              <div class="item" style="display: flex;align-items: center" v-if="formPublish.myNmae === '单价'">
                <div style="width: 250px">修改后租金（单价*面积=月租金）：</div>
                <!--                  <el-input v-if="formPublish.workOrderStatus === '待发起'"  style="width: 250px;margin-top: 20px" v-model="formPublish.rentUpdateReason" placeholder="请输入内容"></el-input>-->
                <div style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center">{{ money }}</div>

              </div>

              <div v-else class="item">
              </div>
              <div class="item">
                <!--                半年租金折扣：{{formPublish.sixMonthDiscount}}%-->
              </div>
<!--              <div class="item">-->
<!--                &lt;!&ndash;                年租金折扣：{{formPublish.yearDiscount}}%&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="item">-->
<!--                &lt;!&ndash;                地址：{{formPublish.apartmentAddress}}&ndash;&gt;-->
<!--              </div>-->

<!--              <div class="item">-->
<!--              </div>-->

<!--              <div class="item">-->
<!--              </div>-->

              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请原因：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"
                          style="width: 350px;margin-top: 20px" v-model="formPublish.rentUpdateReason"
                          placeholder="请输入内容"></el-input>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 400px;">{{ formPublish.rentUpdateReason }}
                </div>
              </div>


              <!--              人才基地小区-->
              <!--              <div class="item" style="display: flex;align-items: center;margin-bottom: 53px" v-if="formPublish.myNmae === '单价'">-->
              <!--                <div style="width: 230px"> 修改后租金（单价*面积=月租金）：</div>-->

              <!--                <div style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center;">{{money}}</div>元-->
              <!--              </div>-->

              <div class="item">
              </div>
              <div class="item">
              </div>
              <div class="item" v-if="active===0">
              </div>
              <div class="item"  >
                附&emsp;&emsp;件： <a  @click="funUrlLookPdf" style="text-decoration: none;" href="javascript:;" >查看附件</a><a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" v-if="active===0">添加附件</a>
              </div>
            </div>
          </div>
          <!--          <div style="height: 40px"></div>-->
          <!--         密码审批-->
          <div v-if="typesuijisu === 3">
            <div class="box_tap">
              <h3>承租信息</h3>
            </div>
            <div class="box_bnt">
              <div class="item">
                承租人：{{ formPublish.leasorName }}
              </div>
              <div class="item">
                手机号码：{{ formPublish.leasorPhone }}
              </div>
              <div class="item">
                证件号码：{{ formPublish.idCard }}
              </div>
              <div class="item">
                承租人类型：{{ formPublish.leasorType === '个人' ? '个人' : '企业' }}
              </div>
              <div class="item">
                房间：{{ formPublish.apartmentName }}
              </div>
              <div class="item">
                合同周期：{{ formPublish.contractPeriod }}
              </div>
              <div class="item">
              </div>
              <div class="item">
              </div>
            </div>
          </div>
          <div v-if="typesuijisu === 3">
            <div class="box_tap">
              <h3>密码信息</h3>
            </div>
            <div class="box_bnt" style="height: 180px">
              <div class="item">
                申请天数：{{ formPublish.days }}天
              </div>
              <div class="item">
                接收号码：{{ formPublish.phone }}
              </div>
              <div class="item">
                历史累计时长：{{ formPublish.totalHistoryDurationStr}}
              </div>
              <div class="item">
              </div>

              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请原因：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"
                          style="width: 350px;margin-top: 20px;margin-left: -30px" v-model="formPublish.passApplyReason"
                          placeholder="请输入内容"></el-input>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 400px;">{{ formPublish.passApplyReason  }}
                </div>
              </div>
              <div class="item">
              </div>

              <div class="item">
              </div>

              <div class="item">

              </div>
              <div class="item"  >
                附&emsp;&emsp;件： <a  @click="funUrlLookPdf" style="text-decoration: none;" href="javascript:;" >查看附件</a><a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" v-if="active===0">添加附件</a>
              </div>
<!--              <div class="item"  >-->
<!--                附&emsp;&emsp;件： <a  @click="funUrlLookPdf" style="text-decoration: none;" href="javascript:;" >查看附件</a><a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" v-if="active===0">添加附件</a>-->
<!--              </div>-->
            </div>
          </div>
          <div v-if="typesuijisu !== 1" style="margin-top: 40px">
            <el-badge v-if="formPublish.handleNum > 0" :value="formPublish.handleNum" :max="99" class="item">
              <el-button @click="chuLijilu" size="small" type="primary">处理记录</el-button>
            </el-badge>
            <el-button v-else @click="chuLijilu" size="small" type="primary">处理记录</el-button>
          </div>


        </div>
      </div>
    </div>
    <div style="text-align: center;margin-top: 50px" v-if="submitItr && type !=='off'">
      <!--      <el-button size="small" v-if="active !== 3 && active !== 4"  @click="clickCancel"  style="width: 80px;background-color: #D8E7FC;border: none">取消</el-button>-->
      <!--      <el-button size="small" v-if="active === 0"  @click="clickChexiao"  type="primary" style="width: 80px;margin-left: 40px;background-color: #72BEDF;border: none">撤销</el-button>-->
      <!--      <el-button size="small" v-if="active === 1"  @click="bohuiclick" type="primary" style="width: 80px;margin-left: 40px;background-color: #72BEDF;border: none">驳回</el-button>-->
      <!--      <el-button size="small" v-if="active === 2"  @click="bohuiclick" type="primary" style="width: 80px;margin-left: 40px;background-color: #72BEDF;border: none">驳回</el-button>-->
      <!--      <el-button size="small" v-if="active === 0"  type="primary"  @click="clickSubmit"  style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">发起</el-button>-->
      <!--      <el-button size="small" v-if="active === 1"   type="primary"  @click="clickAudit"  style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交</el-button>-->
      <!--      <el-button size="small" v-if="active === 2"   type="primary"  @click="clickAudit"  style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交</el-button>-->
      <!--     新版按钮   -->
      <el-button size="small" v-if="active !== 5" @click="clickCancel"
                 style="width: 80px;background-color: #D8E7FC;border: none">取消
      </el-button>
      <el-button size="small" v-if="active === 0" @click="clickChexiao" type="primary"
                 style="width: 80px;margin-left: 40px;background-color: red;border: none">撤销
      </el-button>
      <el-button size="small" v-if="active === 0" @click="clickSubmitBaocun" type="primary"
                 style="width: 80px;margin-left: 40px;background-color: #409EFF ;border: none">保存
      </el-button>
      <el-button size="small" v-if="active === 1 || active === 2 || active === 3" @click="bohuiclick" type="primary"
                 style="width: 80px;margin-left: 40px;background-color: #72BEDF;border: none">驳回
      </el-button>
      <el-button size="small" v-if="active === 0" type="primary" @click="clickSubmit"
                 style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">发起
      </el-button>
      <el-button size="small" v-if="active === 1 || active === 2 || active === 3" type="primary" @click="clickAudit"
                 style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交
      </el-button>
    </div>

    <!--      处理记录弹框-->
    <r-e-dialog title="处理记录" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                @click-cancel="billListClosejilu" v-dialog-drag>
      <r-e-table :query="formSearch" class="bg-white" ref="orderTableRef" :dataRequest="getWordListTableData"
                 :columns="tableColumn"
                 tooltip-effect="light" :height="500" :cell-style="cellStyle">
        <template slot="empty">
          <el-empty/>
        </template>

      </r-e-table>
    </r-e-dialog>
    <!--      驳回原因弹框-->
    <r-e-dialog title="驳回" :visible.sync="bohuiSwitch" show-footer top="30vh" width="500px" :showFooter="false"
                @click-cancel="billListClose" v-dialog-drag>

      <el-form :from="fromObj">
        <el-form-item prop="remark" label-width="80px" label="驳回原因">
          <el-input type="textarea" :rows="4" v-model="fromObj.remark"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top: 50px">
        <el-button size="small" @click="quxiaoClick" style="width: 80px;background-color: #D8E7FC;border: none">取消
        </el-button>
        <el-button size="small" type="primary" @click="bohuiyuanyClick"
                   style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交
        </el-button>
      </div>
    </r-e-dialog>
<!--    附件上传-->
    <dialog-save-contract-file ref="dialogSaveContractFile" :currentHouse="formPublish"
                               :contractInfo="formPublish" @attachment-click="attachmentClick" />
    <dialogLookContractFile ref="dialogLookContractFile"></dialogLookContractFile>
  </r-e-dialog>
</template>

<script>
import {
  getHousingCodeInfoGET,
  approvalWorkOrderGET,
  approvalWorkOrderPUT,
  auditPUT,
  getCzhiListApi,
  getHousingCodeInfo, getHousingCodeInfoPOST, getHousingapprovalPOST, getHousingCodeInfoTow, offLinePassApprovalPUT,
} from "@/api/work-order-management";
import {timeFormat, numberFormat} from "@custom";
import {
  tableWorkOrderColumnjilu,
  tableWorkOrderColumnjiluHETONG
} from "@/views/rental-management/approval-process-workorder/data";
import {MessageError, MessageSuccess, MessageWarning} from "@custom/message";
import {buttonCookies} from "@storage/cookies";

export default {
  name: "dialog-huanfang",
  data() {
    return {
      HETrentUpdateReason: '',//合同备注
      titleTyle: 'success',
      userName: null,
      days: null,
      money: null,
      formSearch: {},
      submitItr: true,
      tableColumn: tableWorkOrderColumnjilu(this),
      dialogVisible: false,
      bohuiSwitch: false,
      active: 3,
      formPublish: null,
      typesuijisu: null,
      id: null,
      billTitle: null,
      billSwitch: false,
      fromObj: {
        remark: null,//驳回
      },
      billList: [],
      submitDate: null,
      type: null,
      topTlile: null,

      // rules: {
      //   remark: [
      //     { required: true, message: '请输入原因', trigger: 'blur' },
      //   ],
      //
      // }
    };
  },
  components: {
    // uploadPictureCard: ()=>import("@/components/Upload/upload-picture-card"),
    dialogSaveContractFile:() => import('@/views/rental-management/approval-process-workorder/components/pant/dialog-save-contract-file.vue'),
    dialogLookContractFile:() => import('@/views/rental-management/approval-process-workorder/components/pant/dialog-look-contract-file.vue')
  },
  methods: {
    // 保存事件
    clickSubmitBaocun(itr = true) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载请您稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      if (this.typesuijisu === 2) {
        console.log(this.formPublish)
        if (!this.formPublish.rentUpdatePrice) {
          MessageWarning('请填写金额')
          return
        }
        if (!this.formPublish.rentUpdateReason) {
          MessageWarning('请填写申请原因')
          return
        }
        if (!this.isNumber(this.formPublish.rentUpdatePrice)) {
          MessageWarning('请规范输入租金价格')
          return
        }
        let data = {
          rentUpdatePrice: this.formPublish.rentUpdatePrice,
          rentUpdateReason: this.formPublish.rentUpdateReason,
          attachment:this.formPublish.attachment
        }
        if (this.formPublish.id && !data.id) data.id = this.formPublish.id
        getHousingCodeInfoPOST(data).then(res => {
          console.log(res)
          if (itr) {
            loading.close();
            MessageSuccess('保存成功')
            this.clickCancel()
          }
        }).catch(err => {loading.close();})
      }
      if (this.typesuijisu === 1) {

        let data = {
          ...this.formPublish,
          contractWorkOrderRemark: this.HETrentUpdateReason ? this.HETrentUpdateReason : '',
        }
        if (this.formPublish.id && !data.id) data.id = this.formPublish.id
        console.log(data)
        getHousingapprovalPOST(data).then(res => {
          console.log(res)
          if (itr) {
            loading.close();
            MessageSuccess('保存成功')
            this.clickCancel()
          }
        })
      }
      if (this.typesuijisu === 3) {
        // let data = {
        //   ...this.formPublish,
        //   contractWorkOrderRemark: this.HETrentUpdateReason ? this.HETrentUpdateReason : '',
        //
        // }formPublish.passApplyReason
        if(!this.formPublish.passApplyReason){
          loading.close();
          MessageError('请输入申请原因')
          return
        }
        let {apartmentUuid,totalHistoryDuration,apartmentId,leasorUuid,leasorId,contractUuid,days,contractId,phone,deleted,passApplyReason,attachment,attachmentPdf,attachmentImg} = this.formPublish
        // attachment =
        let data = {phone,days,totalHistoryDuration,contractId,apartmentUuid,apartmentId,leasorUuid,leasorId,contractUuid,passApplyReason,deleted,attachment,attachmentPdf,attachmentImg}
        console.log(data)
        if (this.formPublish.id && !data.id) data.id = this.formPublish.id
        console.log(data)
        offLinePassApprovalPUT(data).then(res => {
          console.log(res)
          if (itr) {
            loading.close();
            MessageSuccess('保存成功')
            this.clickCancel()
          }
        })
      }
    },
    // 表格颜色事件
    cellStyle({row, column, rowIndex, columnIndex}) {
      let {operateType} = row;
      // if (workOrderStatusName === '待资产部审核' || workOrderStatusName === '待财务部审核' || workOrderStatusName === '待管理员审核') return {color: '#FF0000'};
        if (operateType === '驳回' && columnIndex === 1) return {color: '#FF3718'};
      // if (operateType === '待财务部审核' && columnIndex === 2) return {color: '#FF3718'};
      // if (operateType === '待管理层审核' && columnIndex === 2) return {color: '#FF3718'};
      // if (operateType === '待发起' && columnIndex === 2) return {color: '#FF3718'};

      //  字体颜色
    },

    // 接口
    getWordListTableData(params) {
      return getHousingCodeInfoGET(params);
    },
    async openDialog(data, type) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载请您稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.userName = localStorage.getItem("userName");
      // 打开弹框事件
      console.log(data, 'openDialogopenDialogopenDialog')
      let that = this;
      that.id = data.id;
      that.type = type;
      this.titleTyle = 'success'
      if (data.workOrderTypeName === '合同') that.typesuijisu = 1
      if (data.workOrderTypeName === '租金') that.typesuijisu = 2
      if (data.workOrderTypeName === '离线密码') that.typesuijisu = 3
      let url = ''
      if (this.typesuijisu === 1) url = 'contract/'// 合同审批详情
      if (this.typesuijisu === 2) url = 'rent'// 租金审批详情
      if (this.typesuijisu === 3) url = 'offLinePass'// 离线密码审批详情
      await approvalWorkOrderGET(url, {id: data.id}).then(({info}) => {
        console.log(info)
        this.formSearch = {approvalWordOrderId: data.id}
          if(this.typesuijisu === 1){
            if (info.workOrderStatus === '待发起') that.active = 0
            // 待资产部审核
            if (info.workOrderStatus === '待资产部审核') that.active = 1
            // if (info.workOrderStatus === '待财务部审核') that.active = 2
            if (info.workOrderStatus === '待管理层审核') that.active = 2
            if (info.workOrderStatus === '已完成') that.active = 5
            if (info.workOrderStatus === '已撤销') that.submitItr = false
            if (info.workOrderStatus === '已撤销') {
              that.active = 5
              this.titleTyle = 'message'
            }
          }else if(this.typesuijisu === 3){
            if (info.workOrderStatus === '待发起') that.active = 0
            // 待资产部审核
            if (info.workOrderStatus === '待资产部审核') that.active = 1
            // if (info.workOrderStatus === '待财务部审核') that.active = 2
            if (info.workOrderStatus === '待管理层审核') that.active = 2
            if (info.workOrderStatus === '已完成') that.active = 5
            if (info.workOrderStatus === '已撤销') that.submitItr = false
            if (info.workOrderStatus === '已撤销') {
              that.active = 5
              this.titleTyle = 'message'
            }
          }else {
            if (info.workOrderStatus === '待发起') that.active = 0
            // 待资产部审核
            if (info.workOrderStatus === '待资产部审核') that.active = 1
            if (info.workOrderStatus === '待财务部审核') that.active = 2
            if (info.workOrderStatus === '待管理层审核') that.active = 3
            if (info.workOrderStatus === '已完成') that.active = 5
            if (info.workOrderStatus === '已撤销') that.submitItr = false
            if (info.workOrderStatus === '已撤销') {
              that.active = 5
              this.titleTyle = 'message'
            }
          }
        // }
        if (info.rentalAmountType === 0) {
          info.myNmae = '月租'
        } else {
          info.myNmae = '单价'
          info.MYmonthlyRent = ((info.monthlyRent / 100).toFixed(2) * info.outsideArea).toFixed(2)
        }
        if (this.typesuijisu === 1) this.HETrentUpdateReason = info.contractWorkOrderRemark
        if (this.typesuijisu === 3) this.topTlile =  info.totalHistoryDurationStr.split("天")[0] > 14 ? true : false
        that.formPublish = info;
        that.formPublish.rentUpdatePrice = that.formPublish.rentUpdatePrice / 100
        console.log('formPublish', that.formPublish)
        loading.close();
        that.dialogVisible = true;
      }).catch(err => {
        loading.close();
        MessageError('获取数据失败')
      })
    },
    chuLijilu(type) {
      if (type === '合同') {
        this.tableColumn = tableWorkOrderColumnjiluHETONG(this)
      }else {
        this.tableColumn = tableWorkOrderColumnjilu(this)
      }
      // 这里添加处理记录的逻辑
      this.billSwitch = true
      console.log('点击了处理记录按钮');
    },
    // 上传附件
    attachmentClick(uuid){
      console.log(uuid)
      // alert(uuid)
      let MYfilePDFList = []
      uuid.filePDFList.forEach(item => {
        MYfilePDFList.push(item.uuid)
      })
      this.formPublish.attachment = [...MYfilePDFList,...uuid.imgList].join(",")
      this.formPublish.attachmentPdf = uuid.filePDFList
      this.formPublish.attachmentImg = uuid.imgList
      console.log(this.formPublish,'上传完点击确认然后回馈给父组件')
    },
    clickSubmit() {
      // 发起事件
      let url = ''
      if (this.typesuijisu === 1) url = 'contractApproval'// 合同审批发起
      if (this.typesuijisu === 2) url = 'rentApproval'// 租金审批发起
      if (this.typesuijisu === 3) url = 'offLinePassApproval'// 离线密码发起l
      // getHousingCodeInfo
      // let data =
      if (this.typesuijisu === 3 && !this.formPublish.passApplyReason) {
        MessageWarning('请完善申请原因')
        return
      }

      if (this.typesuijisu === 2 && !this.isNumber(this.formPublish.rentUpdatePrice)) {
        MessageWarning('请规范输入租金价格')
        return
      }
      if (this.typesuijisu === 2 && !this.formPublish.rentUpdatePrice) {
        MessageWarning('请填写金额')
        return
      }
      if (this.typesuijisu === 2 && !this.isNumber(this.formPublish.rentUpdatePrice)) {
        MessageWarning('请规范输入租金价格')
        return
      }
      // await this.clickSubmitBaocun(false)
      //  rentUpdatePrice: this.formPublish.rentUpdatePrice,
      //      rentUpdateReason: this.formPublish.rentUpdateReason,
      // 发起  {id:this.id,passApplyReason:this.passApplyReason,})
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      let params = {}//审批参数
      if (this.typesuijisu === 3) {
        params = {
          id: this.id,
          // rentUpdatePrice: this.formPublish.rentUpdatePrice,
          // rentUpdateReason: this.formPublish.rentUpdateReason,
          attachment: this.formPublish.attachment,
          passApplyReason: this.formPublish.passApplyReason// 离线密码发起备注
        }
           // 离线密码发起附件
        console.log(params)
      }
      if (this.typesuijisu === 2) {
        params = {
          id: this.id,
          rentUpdatePrice: this.formPublish.rentUpdatePrice,
          rentUpdateReason: this.formPublish.rentUpdateReason,
          attachment: this.formPublish.attachment,
        }
            // 离线密码发起附件
            console.log(params)
      }
      if (this.typesuijisu === 1) {
        let {leasorName,leasorPhone,idCard,emergencyPerson,emergencyPhone,idcardAddress ,email,attachment} =  this.formPublish
        params = {leasorName,leasorPhone,idCard,emergencyPerson,emergencyPhone,idcardAddress ,attachment,email,id: this.id,  contractWorkOrderRemark: this.HETrentUpdateReason ? this.HETrentUpdateReason : '',}
        setTimeout(() => {
          getHousingCodeInfoTow(url, params).then(res => {
            MessageSuccess('发起成功')
            loading.close()
            this.clickCancel()
            console.log(res, '提交')
          }).catch(err => {
            console.log(err)
            loading.close()
          }).finally(() => {
            loading.close()
          })
        }, 3000)
      }else {
        setTimeout(() => {
          getHousingCodeInfo(url, params).then(res => {
            MessageSuccess('发起成功')
            loading.close()
            this.clickCancel()
            console.log(res, '提交')
          }).catch(err => {
            console.log(err)
            loading.close()
          }).finally(() => {
            loading.close()
          })
        }, 3000)
      }
      // console.log(params,'params')
    },
    clickAudit() {
      // 审批提交
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      let url = ''
      if (this.typesuijisu === 1) url = 'contract'// 合同审批提交
      if (this.typesuijisu === 2) url = 'rent'// 租金审批提交
      if (this.typesuijisu === 3) url = 'offLinePass'// 离线密码提交
      auditPUT(url, {id: this.id, operateType: '1',}).then(res => {
        MessageSuccess('审批提交成功')
        loading.close()
        console.log(res, '提交')
        this.clickCancel()
      }).catch(res => {
        loading.close()
        console.log(res)
      })
    },
    // 驳回
    bohuiyuanyClick() {
      if (!this.fromObj.remark) {
        // alert(this.fromObj.remark)
        MessageWarning('请输入驳回原因')
        return
      }
      let url = ''
      if (this.typesuijisu === 1) url = 'contract'// 合同审批驳回
      if (this.typesuijisu === 2) url = 'rent'// 租金审批驳回
      if (this.typesuijisu === 3) url = 'offLinePass'// 离线密码驳回
      auditPUT(url, {id: this.id, operateType: '2', rejectReason: this.fromObj.remark}).then(res => {
        console.log(res, '驳回')
        MessageSuccess('驳回成功')
        this.clickCancel()
        this.quxiaoClick()
      }).catch(err => {
        MessageWarning('驳回失败请联系管理员')
      })
    },
    quxiaoClick() {
      //   驳回原因取消
      this.fromObj.remark = ''
      this.bohuiSwitch = false
    },
    clickCancel() {
      // 取消返回事件
      this.$emit('handleSearch');
      this.dialogVisible = false;
      this.bohuiSwitch = false;
      this.formPublish = {};
      this.days = null;
      this.HETrentUpdateReason = ''

    },
    clickChexiao() {
      // 审批提交
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      let url = ''
      if (this.typesuijisu === 1) url = 'contract'// 合同审批撤销
      if (this.typesuijisu === 2) url = 'rent'// 租金审批撤销
      if (this.typesuijisu === 3) url = 'offLinePass'// 离线密码撤销
      auditPUT(url, {id: this.id, operateType: '0',}).then(res => {
        MessageSuccess('审批工单撤销成功')
        loading.close()
        console.log(res, '提交')
        this.clickCancel()
      }).catch(res => {
        loading.close()
        console.log(res)
      })
    },

    numberFormat(number = 0) {
      number = parseFloat(number);
      return numberFormat(number);
    },

    timeFormat(timedate, format = "yyyy-MM-dd") {
      if (timedate) return timeFormat(new Date(timedate), format);
      else return "";
    },
    billListClose() {
      // 关闭处理弹框事件
      this.fromObj.remark = null
    },
    billListClosejilu() {
      // 关闭处理弹框事件
      this.billSwitch = false;
    },
    bohuiclick() {
      this.bohuiSwitch = true;
    },
    isNumber(value) {
      // 判断是否是数字并且大于0
      if (typeof value === 'number' && !isNaN(value) && value > 0) {
        return true;
      }

      // 判断是否是可以转换为大于0的数字的字符串
      if (typeof value === 'string') {
        const numberValue = Number(value);
        return !isNaN(numberValue) && numberValue > 0;
      }
      // 其他情况返回 false
      return false;
    },
    // 上传pdf事件
    funUrlPdf(){
      this.formPublish.attachmentImg ? this.formPublish.contractPhotos = 'IMG+-+' + this.formPublish.attachmentImg : this.formPublish.contractPhotos = null
      // this.formPublish.attachmentPdf ?  this.formPublish.pdfUrl = 'PDF+-+' +  this.formPublish.attachmentPdf: this.formPublish.pdfUrl = null
      if(this.formPublish.attachmentPdf){
        let pdfList = []
        this.formPublish.attachmentPdf.forEach(item => {
          pdfList.push(item.uuid)
        })
        pdfList ?  this.formPublish.pdfUrl = 'PDF+-+' +  pdfList : this.formPublish.pdfUrl = null
      }else {
        this.formPublish.pdfUrl = null
      }
      //   IMG+-+ PDF+-+
      // if(this.formPublish)
      console.log(this.formPublish,'formPublish--传子组件数据')
      this.$refs["dialogSaveContractFile"].openDialog(this.formPublish);
    },
    // 查看附件
    funUrlLookPdf(){
      this.formPublish.attachmentImg ? this.formPublish.contractPhotos = 'IMG+-+' + this.formPublish.attachmentImg : this.formPublish.contractPhotos = null
      if(this.formPublish.attachmentPdf){
        let pdfList = []
        this.formPublish.attachmentPdf.forEach(item => {
          pdfList.push(item.uuid)
        })
        pdfList ?  this.formPublish.pdfUrl = 'PDF+-+' +  pdfList : this.formPublish.pdfUrl = null
      }else {
        this.formPublish.pdfUrl = null
      }

      this.$refs["dialogLookContractFile"].openDialog(this.formPublish);
    }
  },
  watch: {
    'formPublish.rentUpdatePrice'(val) {
      console.log(val)
      if (val) {
        // console.log('999')
        // console.log('999')
        if (this.isNumber(val)) {
          this.money = (val * this.formPublish.outsideArea).toFixed(2);
          return
        }
        MessageWarning('请规范输入租金价格')
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-bottom: 10px;
  padding: 10px 20px;
  border: 1px solid #D7D7D7;
}

.width300 {
  width: 300px;
}

.width100 {
  width: 100px;
}

.boder-right {
  border-right: 1px solid #D7D7D7;
}

.title {
  border-left: 5px #F5A623 solid;
  padding-left: 5px;
  margin: 15px 0;
  font-weight: 600;
}

.bg-E6E6E6 {
  background-color: #E6E6E6;
}

.bg-E2F5FF {
  background-color: #E2F5FF;
}

.bg-F8F8F8 {
  background-color: #F8F8F8;
}

.width33b {
  width: 25%;
}

.padding_0_30 {
  padding: 0 30px
}

.padding_10_30 {
  padding: 10px 30px;
}

.fw600 {
  font-weight: 600;
}

.box_tap {
  border-left: #EEB765 2px solid;
  width: 500px;
  height: 29px;
  line-height: 29px;
  padding-left: 5px;
}

.box_bnt {
  border: #BBBBBB solid 1px;
  width: 100%;
  height: 80px;
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 项目之间的间距，可选 */
  padding: 10px; /* 容器内边距 */
}

.box_bnt_zujin {
  border: #BBBBBB solid 1px;
  width: 100%;
  height: 300px;
  margin-top: 30px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 项目之间的间距，可选 */
  padding: 10px; /* 容器内边距 */
}

.item {
  flex: 1 1 calc(25% - 20px); /* 每个项目占据25%宽度减去间距 */
  color: black;
}

.el-input {
  .el-input__inner {
    border: none;
    border-bottom: #8c939d 1px solid;
    border-radius: 0px;
  }

}

.input_sty {
  margin-top: -10px;
  margin-left: -10px;
  width: 200px
}

</style>
